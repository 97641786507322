@use '@symplr-ux/alloy-theme/dist/scss' as symplr;
@import '@symplr-ux/alloy-theme/dist/fonts/lato.css';
@import '@symplr-ux/alloy-icons/dist/icons.css';
.wide-col>th, .wide-col>td {
    width: 35%;
}

.wide-col-20>th, .wide-col-20>td {
    width: 20%;
}

em {
    color: #9c4527;
}

     
.grid .data-grid-container {
    overflow-y: auto;
    min-height: 500px;
}

body {
    margin:  0;
}

.diff .sympl-card  {
    width: auto; 
    min-height: 70vh;
}

app-tool  {
    font-size: 0.875rem;
}
.search-main .sympl-card {
    width: auto;
}

.diff .td-table-wrapper {
    height: 60vh;
}


.cheat-sheet {
    span, div, p {
        font-size: 0.875rem;
    }
}
